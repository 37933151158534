
html {
  scroll-behavior: smooth;
}

#navbar-full, #home-full, #about-full, #skills-full, #projects-full, #interests-full, #contact-full {
  display: flex;
  background-color: #ffffff;
  color: #221107;
  padding: 0 50px;
  font-weight: 550;
  font-family: 'Frank Ruhl Libre', serif;
}

#navbar-full  {
  justify-content: space-between !important;
  align-items: center;
  padding: 10px 20px;
  opacity: 0.95;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
}

#home-columns {
  background-image: url('../assets/world-map.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0;
  
}
#home-text {
  height: 150px;
  margin-left: -300px
}
#home-intro {
  font-weight: 600; 
  font-style: italic;
  font-size: 25px
}

#logo-initials {
  font-family: 'Roboto Mono', monospace;
  font-weight: 900;
  border: solid 1px;
  padding: 3px 10px;
  margin: 10px;
}

#navbar-sections {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
}

#home-full {
  background-size: cover;
}

#home-name {
  font-size: 70px;
  font-weight: 600;
}

#home-role {
  font-weight: 700;
  font-size: 20px;
  color: #fa7e0a;
}

#about-title, #interests-title, #language-title {
  font-weight: 700;
  font-size: 25px;
  color: #fa7e0a;
  text-align: center;
}

#about-columns, #home-columns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
}

#about-image-column {
  width: 40%;
}
#about-image {
  border-radius: 170px;
}

#about-title-column {
  width: 50%;
  margin-left: 50px;
}
#skills-full {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#skill-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

#skill-section {
  padding-right: 50px;
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}

#icons-pic, #projects-column {
  display: flex;
  flex-wrap:wrap;
  justify-content: space-evenly;
}

#individual-icon {
  margin-right: 40px;
  margin-bottom: 20px;
  width: calc(8%);
}

#card1, #card2, #card3, #card4 {
  width: calc((40%-20px));
  height: 280px;
  margin-right: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  color: #221107;
  border-radius: 20px;
}

#card1:hover {
  background-image:linear-gradient(rgb(46, 44, 44), black)
}
#card2:hover {
  background-image:linear-gradient(rgb(46, 44, 44), black)
}
#card3:hover {
  background-image:linear-gradient(rgb(46, 44, 44), black)
}
#card4:hover {
  background-image:linear-gradient(rgb(46, 44, 44), black)
}

#project-text {
  position:absolute;
  top: 20%;
  transform: translate((-50%, -50%));
  visibility: hidden;
  font-weight: bold;
  padding: 0 40px;
  font-size: 15px;
  text-align: center;
  color: #fefefb

}

#card1:hover #project-text {
  visibility: visible;
}

#card2:hover #project-text {
  visibility: visible;
}
#card3:hover #project-text {
  visibility: visible;
}

#card4:hover #project-text {
  visibility: visible;
}
#card1 {
  background-image: URL('../assets/MarioPacman1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#card2 {
  background-image: URL('../assets/AstronomyPic.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#card3 {
  background-image: URL('../assets/Platester.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#card4 {
  background-image: URL('../assets/Bookopedia.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#icon-skills {
  height: 70px
}

#language-title {
  padding: 40px 0;
  font-size: 22px;
}
#skill-name {
  font-size: 15px;
} 
#skills-languages {
  font-size: 20px;
  word-spacing: 5px;
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

#skills-language-individual {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;

}

#visit-website, #visit-github {
  margin-top: 20px;
  border-radius: 18px;
  border-color: black;
  background-color:#60391d ;
}
#visit-website {
  padding: 10px 20px;
}

#project-links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#link-logo {
  height: 30px;
}

#visit-website a {
  color: #fefefb;
  font-weight: 700;
}

#interests-columns {
  display: flex;
  margin-bottom: 150px;
  margin-top: 50px;
}

.interest {
  margin-right: 40px;
  text-align: center;
  margin-left: 40px;
}

#contact-info {
  display: flex;
  flex-direction: column;
}

#contact-column {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 50px;
  align-items: center;
}
#contact-title {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color:#fa7e0a ;
  margin-bottom: 50px;
}
#contact-github {
  height: 50px;
  margin-top: 15px;
}

#contact-address {
  text-align: center;
  font-size: 15px;
}


a {
  color: #221107;
}
#emoji {
  font-size: 60px;
  margin-left: 30px;
  padding-top: 30px;
}


@media screen and (max-width: 420px){
  #navbar-full{
    padding: 14px 30px !important;
  }
  #navbar-sections {
    flex-direction: column;
    align-items: end !important;
  }
  #home-full {
    padding: 200px 0px 125px 0px;
    text-align: center;
  }
  #home-text {
    margin-left: 0 !important;
    height: 255px !important;
  }
  #home-intro {
    font-size: 20px;
  }
  #home-name {
    font-size: 50px;
    padding: 15px 0;
  }
  #home-role {
    font-size: 18px;
    width: 103%;
  }

  #home-columns {
    flex-direction: column;
    padding-top: 0px;
    margin-top: 0px;
  }

  #emoji {
    margin-left: 0 !important;
    text-align: center;
    font-size: 43px !important;
    padding-top: 8px !important;
  }
  #about-columns {
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
  }
  #about-image-column {
    width: 90%;
  }
  #about-title-column {
    margin: 0px;
    width: auto;
    text-align: center;
    padding-top: 30px;
  }
  #skill-columns {
    margin: 0 -30px;
  }
  #individual-icon {
    width: calc(15%);
    margin-right: 23px;
    margin-bottom: 12px;
  }
  #icon-skills {
    height: 53px;
  }
  #skill-name {
    font-size: 12px;
  }
  #skills-languages {
    font-size: 16px;
    word-spacing: 2px;
  } 
  #skills-language-individual {
    flex-wrap: nowrap !important;
    flex-direction: column;
  }
  #projects-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  #card1, #card2, #card3, #card4 {
    width: 100%;
    height: 200px;
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    margin-right: 0;
    border-radius: 18px;
    margin-bottom: 13px;
  }
  #project-text {
    padding: 0 18px;
    font-size: 11px;
  }
  #card1:hover, #card1:active #project-text {
    visibility: visible;
  }
  
  #card2:hover, #card2:active #project-text {
    visibility: visible;
  }
  #card3:hover, #card3:active #project-text {
    visibility: visible;
  }
  
  #card4:hover, #card4:active #project-text {
    visibility: visible;
  }
  
  #visit-website {
    padding: 8px 28px;
    margin-top: 18px;
    font-size: 12px;
  }

  #link-logo {
    height: 25px;
  }
  #contact-github {
    height: 35px;
  }
  #interests-full {
    margin-top: 50px;
  }
  #interests-columns {
    flex-direction: column;
    margin-bottom: 80px;
  }
  .interest {
    margin-bottom: 60px;
  }
  #contact-column {
    flex-direction: column;
  }
  #contact-address {
    margin-top: 25px;
    font-size: 13px;
  }
}


/* @media screen and (max-width: 770px) {
  #home-full {
    margin-top: 100px
  }
} */